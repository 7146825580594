import React from 'react';
import {BsHouseDoor} from 'react-icons/bs'

const HomeValue = () => {
return(
<div className='tools__item'>
              <div className='tools__label'>Home Valuation</div>
              <a href='https://teamroyalny.idxbroker.com/idx/homevaluation'>
        <BsHouseDoor className='home-icon' />
        </a>
    </div>
    
)
}

export default HomeValue;