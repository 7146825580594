import React from 'react';

const StaffTitle = () => {

    return(
        <div className='staff__title'>
            Team
        </div>
    )
}

export default StaffTitle