import React, {Fragment} from 'react';

const BuyersTitle = (props) => {

    const {title} = props
    return(
        <Fragment>
        <div className='buyers__title'>
            {title}
        </div>
        <div className='buyers__underline' />
        </Fragment>
    )
}

export default BuyersTitle;