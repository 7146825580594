import React from 'react';
import StaffCard from '../staff/StaffCard';

const BuyersCtaOne = () => {
    return(
<div className='buyers__cta-one'>
    <div className='buyers__cta-one--left'>
        <div className='buyers__cta-one--left--bold'>Contact Your Agent</div>
        <br/> 
        <br/> 
        Gary is an industry professional with 20 years of experience. From mortgages to moving in, Gary's got you covered
        <br/> 
        <br/> 
        Start your search today. Available by phone, email, or social media
    </div>
    <div className='buyers__cta-one--right'>
    <StaffCard />
    </div>
</div>
    )
}

export default BuyersCtaOne;