import React from 'react';
import {BsHouseDoor} from 'react-icons/bs'

const HomeValueIcon = () => {
return(
<div className='tools__item'>
              <a href='https://teamroyalny.idxbroker.com/idx/homevaluation'>
        <BsHouseDoor className='home-icon home-icon--large' />
        </a>
    </div>
    
)
}

export default HomeValueIcon;