import React from 'react';
import gary from '../../img/gary.jpg';
import voro from '../../img/voro.png'
import {HiOutlineMail} from 'react-icons/hi';
import {AiOutlinePhone} from 'react-icons/ai';
import {FaFacebook} from 'react-icons/fa';

const StaffCard = () => {

    return(
        <div className='staff-card'>
            <div className='staff-card__left'>
                <div className='staff-card__left--name'>
                    Gary Brar
                <div className='staff-card__left--title'>
                    Senior Agent <img className='staff-card__left--icon' src={voro} /> 
                </div>
                </div>
                <div className='staff-card__left--quote'>
                    "Buying or selling, this is the most exciting time in real estate"
                </div>
                <div className='staff-card__left--contact'>
                <a href="tel://+6312753896">
        <AiOutlinePhone className='staff-card__left--phone-icon' />
        </a>
                <a href="mailto:gary@teamroyalny.com">
        <HiOutlineMail className='staff-card__left--email-icon' />
        </a>
        <a href='https://www.facebook.com/Team-Royal-Gary-Brar-241898166369506'>
        <FaFacebook className='staff-card__left--social-icon'/>
        </a>
                </div>
            </div>
                <img className='staff-card__right' src={gary} alt='Gary'/>
        </div>
    )
}

export default StaffCard;