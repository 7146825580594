import React from 'react';

const FooterMiddle = () => {

    return (
        <div className='footer-middle'>
        <div className='footer-middle__copyright'>
        Copyright © 2020 Gurjeet Brar. All rights reserved.
        </div>
        <div className='footer-middle__copyright'>
            Built By: Anthony Santonocito
            </div>
            </div>
    )
}


export default FooterMiddle;