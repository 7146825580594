import React from 'react';
import {FiMap} from 'react-icons/fi'

const MapSearch = () => {
    return(
        <div className='tools__item'>
              <div className='tools__label'>Map Search</div>
              <a href='https://teamroyalny.idxbroker.com/idx/map/mapsearch'>
        <FiMap className='map-icon' />
        </a>
    </div>
    )
}

export default MapSearch;



