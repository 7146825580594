import React from 'react';
import CarouselDisplay from '../components/carousel/CarouselDisplay'
import Featured from '../components/featured/Featured';
import Staff from '../components/staff/Staff';
import Testimonial from '../components/testimonial/Testimonial';
import Tools from '../components/tools/Tools';
import HomeValue from '../components/homeValue/HomeValue';

const Home = () => {

    return(
        <div className='home'>
            <CarouselDisplay />
            <HomeValue />
            <Staff />
            <Featured />
            <Testimonial />
            <Tools />
        </div>
    )
}

export default Home;