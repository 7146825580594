import React from 'react';
import BuyersCtaOne from '../components/buyers/BuyersCtaOne';
import BuyersCtaTwo from '../components/buyers/BuyersCtaTwo';
import BuyersTitle from '../components/buyers/BuyersTitle';

const Buyers = () => {
    return(
        <div className='buyers__container'>
        <div className='buyers'>
            <BuyersTitle title='Get Started'/>
            <BuyersCtaOne />
        </div>
        <div className='buyers__or'>
            or
            <div className='margin-top-2' />
        </div>
        <BuyersCtaTwo />
        <div className='margin-top-2' />
        </div>
        
    )
}

export default Buyers;