import React from 'react';

import HomeValueIcon from '../tools/HomeValueIcon'

const SellersTools = () => {
    return(
        <div className='buyers__tools'>
            <div className='buyers__tools--title'>
                Free Home Valuation
            </div>
            <div className='tools__container--flex'>
            <div className='tools__container--small'>
            <HomeValueIcon />
           </div>
           </div>
        </div>
    )
}

export default SellersTools;