import React from 'react';
import logo from '../../img/logo.jpg'

const FooterLeft = () => {

    return (
    <div className='footer-left'>
                <img className='footer-left__image' src={logo} alt='logo' />
        </div>
    )
}

export default FooterLeft;