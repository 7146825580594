import React from 'react';
import FeaturedSlider from './FeaturedSlider';

const Featured = () => {

    return(
        <div className='featured'>
            <div className='featured__title'>
                Featured Properties
            </div>
            <div className='featured__underline' />
            <FeaturedSlider />
        </div>
    )
}

export default Featured;