import React from 'react';
import StaffCard from '../staff/StaffCard';

const SellersCtaOne = () => {
    return(
<div className='buyers__cta-one'>
    <div className='buyers__cta-one--left'>
        <div className='buyers__cta-one--left--bold'>Contact Your Agent</div>
        <br/> 
        <br/> 
        Gary is an industry professional with over 20 years of experience. From listing to closing, Gary's got you covered
        <br/> 
        <br/> 
        Sell your house today. Available by phone, email, or social media
    </div>
    <div className='buyers__cta-one--right'>
    <StaffCard />
    </div>
</div>
    )
}

export default SellersCtaOne;