import React from 'react';
import CarouselOne from './Carousel'

const CarouselDisplay = () => {

    return (
    <CarouselOne />
    )
}

export default CarouselDisplay;