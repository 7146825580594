import React from 'react';
import {CgCalculator} from  'react-icons/cg'

const MortgageCalc = () => {
  return (  <div className='tools__item'>
              <div className='tools__label'>Mortgage Calculator</div>
              <a href='https://teamroyalny.idxbroker.com/idx/mortgage'>
        <CgCalculator className='mortgage-icon' />
        </a>
    </div>
  )
}

export default MortgageCalc;