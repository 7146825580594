import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const TestimonialCarousel = () => {
    return (
        <Carousel
        // className='testimonial__carousel'
        showStatus={false}
        showThumbs={false}
        swipeable
        infiniteLoop
        autoPlay
        stopOnHover
        interval={5000}
        
        >
        <div className='testimonial__body'>
        "Team Royal is amazing! Gary & Abhi worked endlessly to get us the best price on our house. 
        <br />
        <br />
        It definitely wasn't easy because after the open house Covid hit hard, but they did it. They provided us with referrals for everything that we needed and they gave us such good advice. 
        <br />
        <br />
        I am so happy that we went with Team Royal. They worked around the clock to get us what we asked for. They went from agents, to friends to feeling like family. The only team to work with is Team Royal!"
            <div className='testimonial__author'>
             -Sara Wattuhewa 
            </div>
        </div>

        <div className='testimonial__body'>
     "The reason we reached out to them is because they've sold 6 of my very close friends homes prior to ours in the same amount of time AND sold it well over the asking price.  
     <br />
        <br />
     I CANNOT say enough about Gary Brar.  The best form of a compliment is simply a referral, as a result of their level of honesty and professionalism, I've now referred them to several personal friends whose homes are either in contract or set up for showings.  
     <br />
        <br />
     Thank you team Royal for being the best team I as well as my friends have had the distinct pleasure of working with.  You gentlemen are the number one team on Long Island and in the tristate area! Thank you!"
            <div className='testimonial__author'>
            -Sharon Gary 
            </div>
        </div>
        </Carousel>
    )
}

export default TestimonialCarousel;