import React from 'react';
import HeaderCenter from './HeaderCenter'
import HeaderLeft from './HeaderLeft'

const Header = () => {

    return (
        <div className='header'>
            <HeaderLeft />
            <HeaderCenter />
        </div>
    )
}

export default Header;