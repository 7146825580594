import "./css/reset.css";
import './css/home-widget.css';
import './css/header.css';
import './css/footer.css';
import './css/carousel.css';
import './css/home.css';
import './css/staff.css';
import './css/testimonial.css';
import './css/featured.css';
import './css/tools.css';
import './css/buyers.css';
import './css/contact.css';
import './components/header/Header';
import Buyers from './pages/Buyers';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Header from "./components/header/Header";
import Footer  from './components/footer/Footer';
import Home from './pages/Home'
import Sellers from './pages/Sellers'
import ContactUs from "./pages/ContactUs";

function App() {
  return (
    <Router>
    <Header />
    <div className='page-container'>
      
      <Switch>
        
        <Route path='/buyers'>
          <Buyers />
        </Route>
        <Route path='/footer'>
          <Footer />
        </Route>
        <Route path='/header'>
          <Header />
        </Route>
        <Route path='/sellers'>
          <Sellers />
        </Route>  
        <Route path='/contactus'>
          <ContactUs />
        </Route>
        <Route path="/">
         <Home />
        </Route>
      </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
