import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {FaFacebook} from 'react-icons/fa'

const HeaderCenter = () => {
const location = useLocation();
const [clicked, setClicked] = useState('')

useEffect(() => {
    setClicked(location.pathname)
}, [location.pathname])

    return (
        <div className='header-center__container'>
            <div className='header-center__top'>
                <a href='https://www.facebook.com/Team-Royal-Gary-Brar-241898166369506'>
            <FaFacebook className='header-center__top--icon'/>
            </a>
                <a className='header-center__top--text' href="tel://+6312753896">(631) 275-3896</a> <a className='header-center__top--text' href="mailto:gary@teamroyalny.com">gary@teamroyalny.com</a>
                <a className='header-center__top--text' href='https://teamroyalny.idxbroker.com/idx/myaccount'>
                    <div>my account</div>
                </a>
            </div>
        <div className='header-center'>
            <a className=  'header-center__link' href='https://teamroyalny.idxbroker.com/idx/search/basic'>Search</a>
            <Link className=  'header-center__link' to='/buyers'>Buyers</Link>
            <Link className=  'header-center__link' to='/sellers'>Sellers</Link>
            <a className=  'header-center__link' href='https://teamroyalny.idxbroker.com/idx/featuredopenhouse'>Homes</a>
            <Link className= 'header-center__link' to='/contactus'>Contact</Link>
        </div>
        </div>
        )
}

export default HeaderCenter;