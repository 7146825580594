import React from 'react';
import logo from '../../img/logo.jpg'
import {useHistory} from 'react-router-dom';

const HeaderLeft = () => {
    const history = useHistory()
    const navHome = () => {
        history.push('/')
    }
    return (
    <div className='header-left' onClick={() => navHome()}>
        <img className='header-left__image' src={logo} alt='logo' />
        </div>
    )
}

export default HeaderLeft;