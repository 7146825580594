import React from 'react';
import HomeValue from './HomeValue';
import MapSearch from './MapSearch';
import MortgageCalc from './MortgageCalc';
import ToolsTitle from './ToolsTitle';

const Tools = () => {
    return(
        <div className='tools'>
            <ToolsTitle />
            <div className='tools__container'>
            <MortgageCalc />
            <HomeValue />
            <MapSearch />
           </div>
        </div>
    )
}

export default Tools;