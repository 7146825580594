import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import beachhouse from '../../img/beachhouse.jpg';
import coverone from '../../img/house1.jpg';
import covertwo from '../../img/kitchen.jpg';
import coverthree from '../../img/salemade.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SmallSearch from '../../widgets/SmallSearch';

const CarouselOne = () => {
        return (
            <div className='carousel-container'>
            <Carousel           
            showStatus={false}
            showThumbs={false}
            swipeable
            infiniteLoop
            autoPlay
            stopOnHover
            interval={5000}

            >
                                <img key='img' src={beachhouse} alt='carousel' /> 
                <img key='img1' src={coverone} alt='carousel'/> 
                <img key='img2' src={covertwo} alt='carousel'/> 
                <img key='img3' src={coverthree} alt='carousel'/>
            </Carousel>
            <div className='small-search__container'>
          <div className='small-search__title'>Find Your New Home</div>
            <SmallSearch />
            </div>
            </div>
        );
    };


export default CarouselOne;