import React from 'react';
import {FaHome} from 'react-icons/fa'
import {BiDownvote} from 'react-icons/bi';

const HomeValue = () => {

    return(<div >
        <a className='home-value' href='https://teamroyalny.idxbroker.com/idx/homevaluation'>
        <div className='home-value__text'>
            What's your home worth?
        </div>
        <div className='home-value__underline' />
        {/* <div className='home-value__finger'>
            <BiDownvote />
        </div> */}
        <div className='home-value__icon'>
            <FaHome />
        </div>
        </a>
    </div>)
}

export default HomeValue;