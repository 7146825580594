import React from 'react';
import StaffCard from './StaffCard';
import StaffTitle from './StaffTitle';

const Staff = () => {
    return(
        <div className='staff'>
            <StaffTitle />
            <div className='staff__underline' />
            <div className='staff__container'>
                <div className='staff__text--container'>
                    <div className='staff__text'>
               <span className='staff__text--bold'>Get More For Your Home: </span> Let us sell your house for more than the asking price! Almost, all of our sales are <span className='staff__text--bold'>ABOVE</span>  asking price.
            </div>
            <div className='staff__text'>
               <span className='staff__text--bold'> Sell Faster: </span> Innovative advertising and open houses guarentee your home is sold within <span className='staff__text--bold'>TEN DAYS!</span>
            </div>

            <div className='staff__text'>
               <span className='staff__text--bold'> Expert Service: </span>  20 years of Long Island real estate experience, means we work to make this a <span className='staff__text--bold'>GREAT EXPERIENCE </span> for you!
            </div>

            </div>
            <StaffCard />
            </div>
            
        </div>
    )
}

export default Staff