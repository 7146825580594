import React from 'react';
import {AiOutlineForm} from 'react-icons/ai'

const BuyersSignup = () => {
    return(
        <div className='tools__item'>
              <div className='tools__label'>Signup</div>
              <a href='https://teamroyalny.idxbroker.com/idx/usersignup'>
        <AiOutlineForm className='signup-icon' />
        </a>
    </div>
    )
}

export default BuyersSignup;