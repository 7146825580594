import React from 'react';
import StaffCard from '../staff/StaffCard';
import BuyersTitle from '../buyers/BuyersTitle';

const ContactPage = () => {
    return(
        <div className='contact'>
            <BuyersTitle title='Contact' />
            <StaffCard />
            <div className='margin-top-2' />
            <BuyersTitle title='Office' />
            <div className='contact__office'>
                <div className='contact__office--address'>
                Main:
                <a className='contact__office--address--a' href="http://maps.google.com/maps?q=600+3rd+Ave,+New+York,+NY+10016">
    {' '}600 3rd Ave, New York, NY 10016</a>
                </div>
                <div className='contact__office--address'>
                Local: 
                <a className='contact__office--address--a' href="http://maps.google.com/maps?q=24+Great+Neck+Rd,+Great+Neck,+NY+11021">
                {' '}24 Great Neck Rd, Great Neck, NY 11021</a>
                </div>
                <div className='contact__office--phone'>
                Phone: <a className='contact__office--address--a'  href="tel://+6312753896">(631) 275-3896</a> 
                </div>
                <div className='contact__office--email'>
                Email: <a className='contact__office--address--a' href="mailto:gary@teamroyalny.com">gary@teamroyalny.com</a>
                </div>
            </div>
        </div>
    )
}

export default ContactPage;