import React from 'react';
import FooterMiddle from './FooterMiddle';
import FooterLeft from './FooterLeft';
import FooterRight from './FooterRight';

const Footer = () => {

    return (
    <div className='footer'>
        <FooterLeft />
        <FooterMiddle />
        <FooterRight />
        </div>
    )
}

export default Footer;