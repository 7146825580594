import React from 'react';
import SellersTools from '../tools/SellersTools';

const SellersCtaTwo = () => {
    return(
<div className='buyers__cta-one'>
    <div className='buyers__cta-one--left'>
    <div className='buyers__cta-one--left--bold'>Get A Home Valuation</div>
        <br />
        <br />
        Your house might be worth more than you think!
        <br/> 
        <br/> 
Fill out our FREE home valuation form
    </div>
    <div className='buyers__cta-one--right'>
    <SellersTools />
     </div>
     

</div>
    )
}

export default SellersCtaTwo;