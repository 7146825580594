import React from 'react';
import {FaFacebook} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import {AiOutlinePhone, AiOutlineInstagram} from 'react-icons/ai';


const FooterRight = () => {

    return (
    <div className='footer-right'>
                        {/* <a href="tel://+6312753896">
        <AiOutlinePhone className='phone-icon' />
        </a> */}
                <a href="mailto:gary@teamroyalny.com">
        <HiOutlineMail className='email-icon' />
        </a>
        <a href='https://www.facebook.com/Team-Royal-Gary-Brar-241898166369506'>
        <FaFacebook className='social-icon'/>
        </a>
        <a href='https://www.instagram.com/gurjeet4501/ '>
        <AiOutlineInstagram className='social-icon__insta'/>
        </a>
        </div>
    )
}

export default FooterRight;