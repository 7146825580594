import React from 'react';
import BuyersTitle from '../components/buyers/BuyersTitle';
import SellersCtaOne from '../components/sellers/SellersCtaOne';
import SellersCtaTwo from '../components/sellers/SellersCtaTwo';

const Sellers = () => {
    return(
        <div className='buyers__container'>
        <div className='buyers'>
            <BuyersTitle title='Get Started' />
            <SellersCtaOne />
        </div>
        <div className='buyers__or'>
            or
            <div className='margin-top-2' />
        </div>
        <SellersCtaTwo   />
        <div className='margin-top-2' />
        </div>
        
    )
}

export default Sellers;