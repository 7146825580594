import Reaxct, { Fragment } from 'react';

const ToolsTitle = () => {
    return(
        <Fragment>
        <div className='tools__title'>
            Tools
        </div>
        <div className='tools__underline' />
        </Fragment>
    )
}

export default ToolsTitle