import React, {useEffect} from 'react';

const SmallSearch = () => {

    useEffect(() => {
        const anchor = document.createElement("a");
        anchor.setAttribute("type", "text/javascript");
        anchor.setAttribute("id", "idxwidgetsrc-42977");
        document.getElementsByClassName("small-search")[0].appendChild(anchor);
    
        const script = document.createElement("script");
        script.setAttribute("src", "https://teamroyalny.idxbroker.com/idx/quicksearchjs.php?widgetid=42977");
        document.getElementsByClassName("small-search")[0].appendChild(script);
      }, []);
    
      return (
          <div className="small-search" />
      );
    };

export default SmallSearch;