import React from 'react';
import BuyersLogin from './BuyersLogin';
import BuyersSignup from './BuyersSignup';

const BuyersTools = () => {
    return(
        <div className='buyers__tools'>
            <div className='buyers__tools--title'>
                My Account
            </div>
            <div className='tools__container--flex'>
            <div className='tools__container--small'>
                <BuyersSignup />
           </div>
           <div className='tools__container--small'>
                <BuyersLogin />
           </div>
           </div>
        </div>
    )
}

export default BuyersTools;