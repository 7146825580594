import React from 'react';
import BuyersTools from '../tools/BuyersTools';

const BuyersCtaTwo = () => {
    return(
<div className='buyers__cta-one'>
    <div className='buyers__cta-one--left'>
    <div className='buyers__cta-one--left--bold'>Search Online</div>
        <br />
        <br />
Open an account and save your favorite listings
        <br/> 
        <br/> 
An agent will be in contact with you to help you with your search

    </div>
    <div className='buyers__cta-one--right'>
    <BuyersTools />
    </div>
</div>
    )
}

export default BuyersCtaTwo;